object.menu {
    max-height: 55px;
    max-width: 55px;
}

object {
  max-width: 40px;
  vertical-align: top;
}

span, h1 {
    font-family: 'M PLUS 1 Code', serif;
    font-size: 30px;
    font-weight: 300;
}

.menu-upper,
.menu-lower,
.menu-lower-two {
  margin: 0px;
  border-right: 2.5px solid;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  border-color: transparent;
}

.menu-upper {
  animation: 
        typing 2s steps(27),
        cursor .4s step-end 6 alternate;
}

.menu-lower {
  opacity: 0;
  animation: 
        typing 2s forwards 2s steps(31),
        cursor .4s step-end 10 alternate;
}

.menu-lower-two {
  opacity: 0;
  animation: 
        typing 2s forwards 4s steps(25),
        cursor .4s step-end infinite alternate;
}

@keyframes cursor {
  50% { border-color: black }
}

@keyframes typing {
  from, to { opacity: 1; }
  from { width: 0; }
  100% { border-color: transparent }
}
.grid-sect-1-container {
    text-align: left;
    padding: 10%;
    min-height: 55vh;
    display: grid;
    grid-template-rows:
    auto
    auto;
  }

  .grid-menu-container {
    text-align: left;
    display: grid;
    align-items: center;
    justify-items: start;
    justify-content: start;
    grid-template-columns: 
    auto
    auto;
  }

  .grid-men-container {
    display: inline-block;
  }

  .grid-submenu-container {
    text-align: left;
    display: grid;
    grid-template-rows: 
    auto;
  }

  span.span-name {
    font-weight: 400;
    font-size: 30px;
  }

  h1.main-link {
    padding-left: 25px;
  }

  h1.main-link a {
    text-decoration: none;
    color: black;
  }

  h1 a#skills-link:hover{
    color: #7DB1B5;
  }

  h1 a#projects-link:hover{
    color: #a39292;
  }

  h1 a#about-link:hover{
    color: #d9d9d9;
  }

  h1 a#contact-link:hover{
    color: #ffd29d;
  }

  h1 a:focus{
    -webkit-tap-highlight-color: #24020242;
  }