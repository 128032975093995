  .grid-contact-inner-container {
    background-image: linear-gradient(to right, rgb(255, 210, 157,1), rgba(125, 177, 181, 1));
    text-align: left;
    display: grid;
    grid-template-columns: 
    1fr;
  }

  form div {
    margin-top: 10px;
    text-align: center;
  }

  .grid-contact-inner-container input, textarea {
    background-color: white;
    border: 1px solid darkred;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 10px;
  }


  input.submit-button {
width: 100px;
background-color: wheat;
  }