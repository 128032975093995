header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    height: 0px;
    
}

header div {
    text-align: right;
    display: grid;
    height: 0px;
    justify-content: end;
}

header .nav object {
    max-width: 50px;
    position: relative;
    z-index: -1;
}

header .nav span#linkedin {
    mask: url(./svg-files/linkedin.svg) no-repeat;
    display: inline-block;
    width: 50px;
    height: 50px;
    background: rgb(6, 103, 110);
    mask-size: contain;
}

header .nav span#file {
    mask: url(./svg-files/file.svg) no-repeat;
    display: inline-block;
    width: 50px;
    height: 50px;
    background: rgb(6, 103, 110);
    mask-size: contain;
}

header .nav span#github {
    mask: url(./svg-files/github.svg) no-repeat;
    display: inline-block;
    width: 50px;
    height: 50px;
    background: rgb(6, 103, 110);
    mask-size: contain;
}

header .nav span#github:hover {
    mask: url(./svg-files/github.svg) no-repeat;
    display: inline-block;
    width: 50px;
    height: 50px;
    background: red;
    mask-size: contain;
}

header .nav span#file:hover {
    mask: url(./svg-files/file.svg) no-repeat;
    display: inline-block;
    width: 50px;
    height: 50px;
    background: red;
    mask-size: contain;
}

header .nav span#linkedin:hover {
    mask: url(./svg-files/linkedin.svg) no-repeat;
    display: inline-block;
    width: 50px;
    height: 50px;
    background: red;
    mask-size: contain;
}