
  .grid-about-inner-container {
    background-image: linear-gradient(to right, rgb(217, 217, 217,1), rgba(255, 210, 157,1));
    text-align: left;
    display: grid;
    grid-template-columns: auto auto;
    justify-items: end;
  }

  .grid-about-inner-container img {
    width: 100%;
    border-radius: 20px;
  }

  .column-img {
    float: left;
    margin: 3%;
  }
  
  @media screen and (min-width: 1024px){
    .column-img {
      min-width: 55%;
      max-width: 55%;
    }
    
    #about button {
      min-width: 65%;
      max-width: 65%;
    }
  }

  @media screen and (max-width: 100px){
    #about button {
      min-width: 95%;
      max-width: 95%;
    }
  }

  #about button {
    cursor: pointer;
    text-align: center;
    background-color: rgb(125, 177, 181);
    border: 6px solid rgb(125, 177, 181);
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    margin: 2% 0% 0% 2%;
    box-shadow: 5px 5px wheat;
    color: wheat;
    top: 100%;
  transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  }

  #about button:hover {
    background-color: wheat;
    border: 6px solid wheat;
    margin: 2% 0% 0% 2%;
    box-shadow: 5px 5px rgb(125, 177, 181);
    color: rgb(125, 177, 181);
  }

  #about .about-paragraph {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: rgb(125, 177, 181);
    border: 6px solid rgb(125, 177, 181);
    margin: 3%;
    border-radius: 5px;
    padding: 10%;
    color: white;
    max-height: 350px;
  }