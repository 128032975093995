  .grid-projects-inner-container {
    background-image: linear-gradient(to left, rgb(217, 217, 217,1), rgba(163,146,146,1));
    text-align: left;
  }

  .column {
    float: left;
    width: 50%;
  }

  @media screen and (max-width: 1024px){
  .column {
    float: left;
    width: 100%;
    margin: 3%;
  }
}
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    width: 95%;
  }
  .card img {
    background: darkred;
    overflow: hidden;
    width: 80%;
    padding: 10%;
  }

  h4 {
    margin-left: 5px;
    margin-block-end: 0px;
  }
  .container button {
    background-color: darkred;
    border: 6px solid darkred;
    margin: 2% 0% 0% 2%;
    box-shadow: 5px 5px wheat;
    color: wheat;
  }

  .container button:hover {
    background-color: wheat;
    border: 6px solid wheat;
    margin: 2% 0% 0% 2%;
    box-shadow: 5px 5px darkred;
    color: darkred;
  }

  .container p {
    color: wheat;
    text-decoration: none;
    padding: 2%;
    color: wheat;
    background-color: darkred;
    margin: 0 2% 2% 2%;
  }

  .container .heading-beerlab {
    margin-left: 2%;
    background-color: wheat;
    padding: 1%;
    color: darkred;
  }

.right object {
  transform: rotate(180deg);
  overflow: hidden;
}