  .grid-skills-inner-container {
    background-image: linear-gradient(to right, rgba(125, 177, 181,1), rgba(163,146,146,1));
      text-align: center;
      display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: stretch;
    align-items: end;
    justify-items: center;
    }

    .grid-skills-inner-container h3 {
      display: inline-block;
      grid-template-columns: auto;
      font-family: 'M PLUS 1 Code', sans-serif;
      font-weight: 400;
      color: lightblue;
      background-color: rgb(103, 84, 84);
      border-radius: 20px;
      padding: 10px;
      margin: 10px;
      text-align: start;
    }

    h3 span#skill-subheading {
      font-size: 20px;
      font-weight: 500;
      color: tan;
    }

    h3 span#bracket {
      font-size: 20px;
      font-weight: 400;
      color: darkred;
    }