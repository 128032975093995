@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1+Code:wght@300;500&display=swap');

.grid-container {
  scroll-behavior: smooth;
  text-align: left;
  padding-right: 5%;
  padding-left: 5%;
  min-height: 55vh;
  display: grid;
  grid-template-rows: 
  auto
  1fr
  auto;
}

object {
  position: relative; 
  z-index: -1
}

h1.left a ,
h1.right a{
  text-decoration: none;
  color: black;
}

h1.left {
  font-weight: 300;
  text-align: left;
}

h1.right {
  font-weight: 300;
  text-align: right;
}

.grid-container div#about-inner,
#skills-inner,
#projects-inner,
#contact-inner
 {
  min-height: 32vh;
  padding: 3%;
  box-shadow: inset -2px -2px 6px black,
              inset 2px 2px 6px black;
}